<template>
  <div class="page_">
    <a @click="$router.go(-1)" href="javascript:;">{{
      $t('tips[42]') || '返回上一页'
    }}</a>
    <a-form :form="searchForm" layout="horizontal" @submit="handleSearchForm">
      <a-form-item
        :label="$t('labels[0]') || '查询项'"
        class="c_form_item_ pad15_ h58_"
      >
        <a-row :gutter="12">
          <a-col :span="16">
            <a-radio-group
              style="margin-left: 12px;"
              @change="onAChange"
              v-model="aValue"
            >
              <a-radio :value="1">{{ $t('cols[4]') || '保险卡号' }}</a-radio>
              <a-radio :value="2">{{ $t('cols[3]') || '证件号' }}</a-radio>
              <a-radio :value="3">{{ $t('cols[0]') || '姓名' }}</a-radio>
              <a-radio :value="4">{{ $t('labels[4]') || '保单号' }}</a-radio>
              <a-radio :value="5">{{ $t('labels[5]') || '病历号' }}</a-radio>
              <a-radio :value="6">{{
                $t('labels[10]') || '中间带交易号'
              }}</a-radio>
              <a-radio :value="7">{{
                $t('preAuth.serNum1') || '理赔编号'
              }}</a-radio>
            </a-radio-group>
          </a-col>
          <a-col class="p_static_" :span="8">
            <div class="right_">
              <a-checkbox @change="onSelectAll">{{
                $t('btns[3]') || '勾选全部记录'
              }}</a-checkbox>
              <a-button @click="addCase" type="primary">{{
                $t('btns[5]') || '添加'
              }}</a-button>
              <!-- <a-button
                  @click="exportData"
                  style="margin-left: 24px;"
                  type="primary"
                  >{{ $t("btns[4]") || "导出数据" }}</a-button
                > -->
            </div>
          </a-col>
        </a-row>
      </a-form-item>
      <a-row class="c_form_item_ h58_" :gutter="12">
        <a-col v-if="aValue == 2" :span="6" class="marLeft_20">
          <a-form-item :label="$t('claimDetail[20]') || '证件类型'">
            <a-select
              v-decorator="['idType']"
              :placeholder="$t('Modals[21]') || '请选择'"
            >
              <a-select-option key="1" value="00">{{
                $t('labels[34]') || '身份证'
              }}</a-select-option>
              <a-select-option key="2" value="01">{{
                $t('labels[35]') || '护照'
              }}</a-select-option>
              <a-select-option key="3" value="02">{{
                $t('labels[36]') || '其他'
              }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item :label="$t('labels[0]') || '查询内容'">
            <a-input
              @change="handleContentChangeWithCommon"
              v-decorator="[
                'content',
                {
                  initialValue: searchContent
                }
              ]"
              :placeholder="cPlaceholder"
            ></a-input>
          </a-form-item>
        </a-col>
        <a-col v-if="aValue == 3" :span="6" class="marLeft_20">
          <a-form-item :label="$t('cols[1]') || '出生日期'">
            <a-date-picker
              v-decorator="['birthDate']"
              :placeholder="$t('Modals[21]') || '请选择'"
              valueFormat="YYYY-MM-DD"
              :format="dateFormat"
            ></a-date-picker>
          </a-form-item>
        </a-col>
        <a-col :span="7" class="marLeft_20" v-if="aValue !== 7">
          <a-form-item :label="$t('labels[11]') || '就诊日期'">
            <a-range-picker
              v-decorator="['rangeDate', { initialValue: undefined }]"
              :placeholder="[
                $t('tips[27]') || '开始时间',
                $t('tips[28]') || '结束时间'
              ]"
              valueFormat="YYYY-MM-DD"
              :format="dateFormat"
            ></a-range-picker>
          </a-form-item>
        </a-col>

        <a-col :span="2" class="marLeft_20">
          <a-button html-type="submit" type="primary">{{
            $t('btns[0]') || '查询'
          }}</a-button>
        </a-col>
        <a-col :span="2">
          <a-button type="primary" ghost @click="resetConditions">{{
            $t('btns[18]') || '重置'
          }}</a-button>
        </a-col>
      </a-row>
    </a-form>
    <a-table
      :rowKey="record => record.claimsId"
      style="margin-top: 20px;"
      :scroll="{ x: 1600 }"
      :columns="columns"
      :dataSource="data"
      @change="handlePageChange"
      :pagination="pagination"
      :row-selection="rowSelection"
    >
      <template slot="claimsId" slot-scope="text, record">
        <router-link :to="`/claimDetail/${text}?cardNo=${record.cardNo}`">{{
          text
        }}</router-link>
      </template>
      <template
        v-for="col in [
          'dueTotal',
          'paidToClaimant',
          'partienPrepaid',
          'selfPaidMemoney',
          'paidByClaimant'
        ]"
        :slot="col"
        slot-scope="text"
      >
        {{ text | addCommas }}
      </template>
      <template slot="do" slot-scope="text, record">
        <claim-buttons
          v-on:onSuccess="fetchData"
          :record="record"
          type="1"
        ></claim-buttons>
      </template>
    </a-table>
    <claim-about-modals></claim-about-modals>
    <a-spin class="loading_" size="large" :spinning="spinning"></a-spin>
    <!-- 批量上传弹框 -->
    <div class="mask" v-if="showUploadDialog">
      <div class="dialog_box">
        <!-- <h4>{{ $t("dashboard.upload") || "理赔件批量上传" }}</h4> -->
        <!-- <p class="tips">
            {{
              $t("dashboard.documents") ||
                "请按以下顺序排放理赔材料：理赔申请表（写上理赔号）+ 账单 + 病例（化验报告）+ 其它"
            }}
          </p> -->
        <!-- <p class="tips">{{ $t("dashboard.format") || "请使用JPG格式" }}</p> -->
        <div>
          <a-upload
            :remove="handleRemove"
            :beforeUpload="beforeUpload"
            :file-list="fileList"
            class="upload-list-inline"
          >
            <span class="lable"
              >{{ $t('dashboard.claimDocuments') || '理赔材料' }}:</span
            >
            <a-button>
              {{ $t('dashboard.selectFile') || '选择文件' }}
            </a-button>
          </a-upload>
        </div>
        <div class="remark_box">
          <p class="lable">{{ $t('dashboard.remark') || '备注' }}:</p>
          <a-textarea
            v-model="remark"
            placeholder="请输入备注"
            :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </div>
        <div class="btn_group">
          <a-button @click="handleUpload" type="primary" class="mar_right25">{{
            $t('dashboard.confirm') || '确认'
          }}</a-button>
          <a-button @click="cancle">{{
            $t('dashboard.cancel') || '取消'
          }}</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonMixin from '@/mixins/common'
import ClaimAboutModals from '../components/ClaimAboutModals'
import ClaimButtons from '../components/ClaimButtons'
import authorizedMixin from '@/mixins/authorized'
import Bus from '@/utils/Bus'
import { MESSAGE_CHANNEL } from '@/utils/constants'

export default {
  data() {
    const columns = [
      // {
      //   title: "",
      //   scopedSlots: { customRender: "check" },
      //   width: "50px"
      // },
      {
        title: this.$t('common.index'),
        width: '50px',
        customRender: (t, r, index) => {
          return parseInt(
            `${(this.pagination.current - 1) * this.pagination.pageSize +
              (index + 1)}`
          )
        }
      },
      {
        title: this.$t('claimCols[0]') || '理赔编号',
        dataIndex: 'claimsId',
        key: 'claimsId',
        sorter: true,
        scopedSlots: { customRender: 'claimsId' }
      },
      {
        title: this.$t('cols[0]') || '姓名',
        dataIndex: 'patientName',
        key: 'patientName',
        sorter: true
      },
      {
        title: this.$t('cols[4]') || '保险卡号',
        dataIndex: 'cardNo',
        key: 'cardNo',
        sorter: true
      },
      {
        title: this.$t('cols[1]') || '生日',
        dataIndex: 'birthDate',
        key: 'birthDate',
        sorter: true
      },
      {
        title: this.$t('labels[11]') || '就诊日期',
        dataIndex: 'admissionDate',
        key: 'admissionDate',
        sorter: true
      },
      {
        title: this.$t('claimCols[1]') || '账单金额',
        dataIndex: 'dueTotal',
        key: 'dueTotal',
        scopedSlots: { customRender: 'dueTotal' },
        sorter: true
      },
      {
        title: this.$t('claimCols[1]') || 'MRN',
        dataIndex: 'mrn',
        key: 'mrn',
        scopedSlots: { customRender: 'mrn' },
        sorter: true
      },
      {
        title: this.$t('claimCols[2]') || '赔付金额',
        dataIndex: 'paidToClaimant',
        key: 'paidToClaimant',
        scopedSlots: { customRender: 'paidToClaimant' },
        sorter: true
      },
      {
        title: this.$t('claimCols[3]') || '自负金额',
        dataIndex: 'paidByClaimant',
        key: 'paidByClaimant',
        scopedSlots: { customRender: 'paidByClaimant' },
        sorter: true
      },
      {
        title: this.$t('claimCols[4]') || '客户已付金额',
        dataIndex: 'partienPrepaid',
        key: 'partienPrepaid',
        scopedSlots: { customRender: 'partienPrepaid' },
        sorter: true
      },
      {
        title: this.$t('claimCols[5]') || '应收自付额',
        dataIndex: 'selfPaidMemoney',
        key: 'selfPaidMemoney',
        scopedSlots: { customRender: 'selfPaidMemoney' },
        sorter: true
      },
      {
        title: this.$t('labels[10]') || '中间带交易号',
        dataIndex: 'approval',
        key: 'approval',
        sorter: true
      },
      {
        title: this.$t('claimCols[6]') || '中间带付款日期',
        dataIndex: 'chequeDate',
        key: 'chequeDate',
        sorter: true
      },
      {
        title: this.$t('claimCols[7]') || '保险人',
        dataIndex: 'longTime',
        key: 'longTime',
        sorter: true
      },
      {
        title: this.$t('claimCols[8]') || '保险责任',
        dataIndex: 'coverageName',
        key: 'coverageName',
        sorter: true
      },
      {
        title: this.$t('claimDetail[10]') || '赔付结论',
        dataIndex: 'claimsResult',
        key: 'claimsResult',
        sorter: true
      },
      {
        title: this.$t('claimCols[10]') || '审核进度',
        dataIndex: 'appLoadingName',
        key: 'appLoadingName',
        sorter: true
      },
      {
        width: 160,
        title: this.$t('cols[7]') || '操作',
        dataIndex: 'do',
        key: 'do',
        fixed: 'right',
        scopedSlots: { customRender: 'do' }
      }
    ]
    return {
      backBtn: '< 返回',
      conFlag: '1',
      // visibleChange: false,
      // changeCostForm: this.$form.createForm(this),
      showUploadDialog: false, // 批量上传弹框显示
      remark: '', // 备注
      fileList: [],
      selectedRowKeys: [],
      columns,
      data: [],
      unReceMessageSize: undefined,
      noBillSize: undefined,
      refsize: undefined,
      closedCaseSize: undefined,
      underReviewSize: undefined,
      claimSize: undefined,
      claimsRejectedSize: undefined,
      id: 1,
      aValue: 1,
      searchForm: this.$form.createForm(this),
      cPlaceholder: this.$t('tips[10]') || '请输入保险卡号',
      pagination: {
        pageSize: 10,
        total: 0,
        currentPage: 1,
        current: 1,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100', '150', '200'],
        onShowSizeChange: (current, pageSize) =>
          (this.pagination.pageSize = pageSize)
      },
      pageOrderBy: '',
      dateFormat: ['YYYY-MM-DD', 'YYYY/MM/DD', 'YYYYMMDD'],
      searchContent: ''
    }
  },
  mixins: [commonMixin, authorizedMixin],
  components: {
    'claim-about-modals': ClaimAboutModals,
    'claim-buttons': ClaimButtons
  },
  mounted() {
    this.fetchData()
  },

  computed: {
    // 多选;
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.handleSelectChange
      }
    }
  },
  methods: {
    handleSelectChange(keys) {
      this.selectedRowKeys = keys
    },
    // 理赔件批量上传
    openUploadDialog() {
      this.showUploadDialog = true
    },
    // 批量上传弹框确认
    confirm() {},
    // 批量上传弹框取消
    cancle() {
      this.showUploadDialog = false
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file)
      const newFileList = this.fileList.slice()
      newFileList.splice(index, 1)
      this.fileList = newFileList
    },
    beforeUpload(file) {
      this.fileList = [file]
      return false
    },
    handleUpload() {
      const { fileList } = this
      const formData = new FormData()
      if (!fileList.length) {
        this.$message.error(this.$t('dashboard.zipNotEmpty'))
        return
      }
      fileList.forEach(file => {
        formData.append('file', file)
      })
      formData.append('remark', this.remark)

      this.uploading = true
      this.$apis.batchUploadImages(formData).then(res => {
        if (res.data.code !== 200) {
          this.$message.error(res.data.msg || '资料上传失败')
        }
        this.uploading = false
        this.$message.success(this.$t('dashboard.tips'))
        this.fileList = []
        this.remark = ''
        this.showUploadDialog = false
      })
    },
    showPrintModal(printUrl) {
      // 打印理赔、结算
      Bus.$emit(MESSAGE_CHANNEL, {
        type: 'handlePrint',
        data: {
          printUrl,
          batchPrint: true
        },
        title: this.$t('btns[5]') || '批量打印理赔解释',
        printType: 'EOB'
      })
    },
    batchPrint() {
      this.$apis
        .batchPrint({
          cIds: this.selectedRowKeys.join(','),
          printType: '4022'
        })
        .then(res => res.data)
        .then(data => {
          this.showPrintModal(data.data.printUrl)
          // this.printUrl = data.data.printUrl
        })
    },
    handlePageChange(page, filters, sorter) {
      if (!this.data || !this.data.length) {
        return false
      }
      this.pagination.current = page.current
      let { field, order } = sorter
      field = field === 'appLoadingName' ? 'apploading' : field
      order = order ? (order === 'ascend' ? 'asc' : 'desc') : undefined
      this.pageOrderBy = order ? field + ' ' + order : ''
      this.fetchData()
    },
    handleSearchForm(e) {
      e && e.preventDefault()
      this.pagination.current = 1
      this.fetchData()
    },
    exportData() {
      const params = this.fetchData(1, 1)
      this.$apis
        .batchReportExportData({
          ...params
        })
        .then(res => {
          const blob = new Blob([res.data], { type: 'application/zip' })
          const filename = res.headers['content-disposition']
          const downloadElement = document.createElement('a')
          const href = window.URL.createObjectURL(blob) //创建下载的链接
          downloadElement.href = href
          ;[downloadElement.download] = [filename.split('=')[1]]
          document.body.appendChild(downloadElement)
          downloadElement.click() //点击下载
          document.body.removeChild(downloadElement) //下载完成移除元素
          window.URL.revokeObjectURL(href) //释放blob对象
        })
    },
    fetchData(no, returnOptions = 0) {
      let params = {
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        conFlag: this.conFlag,
        pageOrderBy: this.pageOrderBy
      }
      let content = undefined
      this.searchForm.validateFields((err, options) => {
        if (!err) {
          if (options) {
            switch (this.aValue) {
              case 1:
                content = options.content.split('-').join('')
                params.cardNo = content == '80001428' ? '' : content
                break
              case 2:
                params.idNumber = options.content
                break
              case 3:
                params.name = options.content
                break
              case 4:
                params.policyNo = options.content
                break
              case 5:
                params.disabilityNo = options.content
                break
              case 6:
                params.approval = options.content
                break
              case 7:
                params.claimsId = options.content
                break
            }
            if (options.rangeDate && options.rangeDate.length > 1) {
              params.startTime = options.rangeDate[0]
              params.endTime = options.rangeDate[1]
            }
            if (options.idType) {
              params.idType = options.idType
            }
            if (options.birthDate) {
              params.birthDate = options.birthDate
            }
          }
        }
      })
      if (returnOptions) {
        return params
      }
      this.$apis
        .queryClaimsList(params)
        .then(res => res.data.data)
        .then(data => {
          const {
            unReceMessageSize,
            noBillSize,
            refsize,
            closedCaseSize,
            underReviewSize,
            claimSize,
            claimsRejectedSize
          } = data
          this.data = data.medicialRecordVoList
          this.pagination.total = data.totalSize
          this.unReceMessageSize = unReceMessageSize
          this.noBillSize = noBillSize
          this.refsize = refsize
          this.closedCaseSize = closedCaseSize
          this.underReviewSize = underReviewSize
          this.claimSize = claimSize
          this.claimsRejectedSize = claimsRejectedSize
        })
        .catch(() => {
          this.data = []
          this.pagination.total = 0
        })
    },
    handleRecordSelect(e, record, index) {
      if (this.selectedRowKeys.indexOf(record.claimsId) == -1) {
        this.selectedRowKeys.push(record.claimsId)
      } else {
        this.selectedRowKeys.splice(index, 1)
      }
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    onSelectAll(e) {
      if (e.target.checked) {
        this.selectedRowKeys = this.data.map(item => item.claimsId)
      } else {
        this.selectedRowKeys = []
      }
    },
    onAChange(e) {
      const val = e.target.value
      let str = ''
      this.searchContent = ''
      this.searchForm.setFieldsValue({
        content: ''
      })
      switch (val) {
        case 1:
          str = this.$t('tips[10]') || '请输入保险卡号'
          this.searchContent = ''
          this.searchForm.setFieldsValue({
            content: ''
          })
          break
        case 2:
          str = this.$t('tips[11]') || '请输入客户证件号'
          break
        case 3:
          str = this.$t('tips[12]') || '请输入客户姓名'
          break
        case 4:
          str = this.$t('tips[13]') || '请输入客户保单号'
          break
        case 5:
          str = this.$t('tips[14]') || '请输入客户病历号'
          break
        case 6:
          str = this.$t('tips[40]') || '请输入凭单上APP#后面的数字'
          break
        case 7:
          str = this.$t('tips[60]') || '请输入理赔编号'
          break
      }
      this.cPlaceholder = str
    },
    resetConditions() {
      this.searchContent = ''
      this.searchForm.setFieldsValue({
        content: '',
        rangeDate: []
      })
      switch (this.aValue) {
        case 1:
          this.searchContent = ''
          this.searchForm.setFieldsValue({
            content: ''
          })
          break
        case 2:
          this.searchForm.setFieldsValue({
            idType: ''
          })
          break
        case 3:
          this.searchForm.setFieldsValue({
            birthDate: null
          })
          break
      }
    },

    async addCase() {
      const _that = this
      if (this.selectedRowKeys.length === 0) {
        this.$message.warning('请先选择案件')
        return
      }

      const result = await this.$apis.addClaimsToBatch({
        batchNo: this.$route.params.id,
        claimsIdList: [...this.selectedRowKeys]
      })
      if (result.data.code === 200) {
        this.$success({
          title: '案件：',
          // JSX support
          content: (
            <div>
              <div style="background: #f5f5f5;width:280px;display: flex;flex-wrap: wrap;gap:2px;height: 200px;overflow-y: scroll;">
                {_that.selectedRowKeys.map((item, index) => {
                  if (index === _that.selectedRowKeys.length - 1) {
                    return <p style="height:22px;">{item}</p>
                  }
                  return <p style="height:22px;">{item}、</p>
                })}
              </div>
              <p>已成功添加至{_that.$route.params.id}批次</p>
            </div>
          )
        })
        this.fetchData()
      }
    }
  }
}
</script>
<style>
.upload-list-inline .ant-upload-list div {
  display: inline-block;
  margin-right: 20px;
  width: 300px;
}
</style>
<style lang="scss" scoped>
.mar_right25 {
  margin-right: 25px !important;
}
.why-icon {
  width: 18px;
  vertical-align: middle;
  margin-top: -4px;
}
.margin-right5 {
  margin-right: 5px;
}
.mask {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  .dialog_box {
    background: #fff;
    padding: 25px 40px;
    border-radius: 5px;
    h4 {
      width: 100%;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
    }
    .tips {
      color: red;
    }
    .remark_box {
      width: 625px;
      margin-top: 15px;
    }
    .lable {
      font-weight: bold;
      margin-right: 10px;
    }
    .btn_group {
      margin-top: 15px;
      width: 100%;
      text-align: center;
    }
  }
}
.page_ {
  .pad {
    padding: 10px 15px;
  }
}
.algin_left {
  text-align: right;
}
</style>
