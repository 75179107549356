<template>
  <div class="page_ custom_table_style_">
    <div class="clearfix mrg_b15_">
      <a @click="$router.go(-1)" href="javascript:;">{{
        $t("tips[42]") || "返回上一页"
      }}</a>
      <div class="f_r_">
        <claim-buttons
          v-on:onSuccess="fetchData"
          type="2"
          :record="detailInfo"
        ></claim-buttons>
      </div>
    </div>
    <dl class="section_">
      <dt class="title_">{{ $t("claimDetail[0]") || "就诊信息" }}</dt>
      <dd class="content_">
        <a-row class="item_">
          <a-col :span="8">
            <div>
              {{ $t("claimDetail[1]") || "医院" }}：{{
                detailInfo.providerName
              }}
            </div>
            <div v-if="detailInfo.claimType != 'OP'">
              {{ $t("claimDetail[3]") || "出院日期" }}：{{
                detailInfo.docsubmitDate
              }}
            </div>
          </a-col>
          <a-col :span="8">
            <div>
              {{ $t("labels[11]") || "就诊日期" }}：{{
                detailInfo.admissionDate
              }}
            </div>
            <div>
              {{ $t("claimDetail[4]") || "币种" }}：{{ detailInfo.currcdOrig }}
            </div>
          </a-col>
          <a-col :span="8">
            <div v-if="detailInfo.claimType != 'OP'">
              {{ $t("claimDetail[2]") || "住院天数" }}：{{
                detailInfo.daysInHospital
              }}天
            </div>
            <div>
              {{ $t("claimDetail[5]") || "汇率" }}：{{ detailInfo.currrtOrg }}
            </div>
          </a-col>
        </a-row>
        <a-row class="item_">
          <a-col :span="8">
            <div>
              {{ $t("cols[0]") || "病人姓名" }}：{{ detailInfo.patientName }}
            </div>
            <div>
              {{ $t("cols[1]") || "出生日期" }}：{{ detailInfo.birthDate }}
            </div>
          </a-col>
          <a-col :span="8">
            <div>
              {{ $t("cols[2]") || "性别" }}：{{
                detailInfo.gender == "女"
                  ? $t("Modals[19]") || "女"
                  : $t("Modals[20]") || "男"
              }}
            </div>
            <div>
              {{ $t("claimDetail[20]") || "证件类型" }}：{{ detailInfo.idType }}
            </div>
          </a-col>
          <a-col :span="8">
            <div>
              {{ $t("claimDetail[21]") || "年龄" }}：{{ detailInfo.age
              }}{{ $t("tips[8]") || "岁" }}
            </div>
            <div>
              {{ $t("labels[2]") || "证件号码" }}：{{ detailInfo.newIc }}
            </div>
          </a-col>
        </a-row>
        <a-row class="item_">
          <a-col :span="8">
            <div>
              {{ $t("claimDetail[22]") || "主被保险人" }}：{{
                detailInfo.employeeName
              }}
            </div>
            <div>
              {{ $t("claimDetail[7]") || "邮箱" }}：{{ detailInfo.email }}
            </div>
          </a-col>
          <a-col :span="8">
            <div>
              {{ $t("claimDetail[6]") || "关系" }}：{{
                detailInfo.relationship
              }}
            </div>
            <div>
              {{ $t("claimDetail[29]") || "科室详情" }}：{{
                detailInfo.physicianDepartment
              }}
            </div>
          </a-col>
          <a-col :span="8">
            <div>
              {{ $t("claimDetail[23]") || "手机号" }}：{{
                detailInfo.patientHpno
              }}
            </div>
            <div></div>
          </a-col>
        </a-row>
        <a-row class="item_ no_border_">
          <a-col :span="24">
            {{ $t("claimDetail[8]") || "诊断" }}：{{
              detailInfo.primaryDiagnosis
            }}
          </a-col>
        </a-row>
      </dd>
    </dl>
    <dl class="section_">
      <dt class="title_">{{ $t("claimDetail[9]") || "理赔信息" }}</dt>
      <dd class="content_">
        <a-row class="item_ no_border_">
          <a-col :span="6">
            <div>
              {{ $t("claimCols[0]") || "理赔编号" }}：{{ detailInfo.claimsId }}
            </div>
          </a-col>
          <a-col :span="6">
            <div>
              {{ $t("claimDetail[10]") || "赔付结论" }}：{{
                detailInfo.calimsResult
              }}
            </div>
          </a-col>
          <a-col :span="6">
            <div>
              {{ $t("claimDetail[11]") || "责任" }}：{{ detailInfo.loaDesc }}
            </div>
          </a-col>
          <a-col :span="6">
            <div>
              {{ $t("claimCols[10]") || "审核进度" }}：<span
                class="highlight_"
                >{{ detailInfo.process }}</span
              >
            </div>
          </a-col>
        </a-row>
        <a-table :columns="columns" :dataSource="data" bordered>
          <template
            v-for="item in [
              'amtIncurrdOrg',
              'amtDiscount',
              'amtIncurred',
              'amtApproved',
              'amtToPay'
            ]"
            :slot="item"
            slot-scope="text"
          >
            {{ text | addCommas }}
          </template>
          <template slot="footer">
            <a-row class="sum_wrap_">
              <a-col :span="4" class="sum_">{{
                $t("claimDetail[16]") || "总计"
              }}</a-col>
              <a-col :span="4">{{
                detailInfo.incurredOrgTotal | addCommas
              }}</a-col>
              <a-col :span="4">{{
                detailInfo.amtDiscountTotal | addCommas
              }}</a-col>
              <a-col :span="4">{{
                detailInfo.amtIncrrredTotal | addCommas
              }}</a-col>
              <a-col :span="4">{{
                detailInfo.amtApprovedTotal | addCommas
              }}</a-col>
              <a-col :span="4">{{
                detailInfo.amtToPayTotal | addCommas
              }}</a-col>
            </a-row>
          </template>
        </a-table>
        <a-row class="item_ no_border_">
          <a-col :span="24">
            {{ $t("claimDetail[17]") || "审核结论" }}：{{ detailInfo.remarks }}
          </a-col>
        </a-row>
      </dd>
    </dl>
    <dl class="section_">
      <dt class="title_">{{ $t("claimDetail[18]") || "付款情况" }}：</dt>
      <dd class="content_">
        <a-row class="item_ no_border_">
          <a-col :span="9">
            <div>
              {{ $t("Modals[28]") || "付款批单" }}：{{ detailInfo.pvNo }}
            </div>
          </a-col>
          <a-col :span="9">
            <div>
              {{ $t("claimDetail[19]") || "付款日期" }}：{{
                detailInfo.chequeDate
              }}
            </div>
          </a-col>
        </a-row>
      </dd>
    </dl>
    <claim-about-modals></claim-about-modals>
  </div>
</template>
<style lang="scss" scoped>
.f_r_ {
  float: right;
}
.mrg_b15_ {
  margin-bottom: 15px;
}
.highlight_ {
  color: #00bd59;
}
.sum_wrap_ {
  text-align: center;
  line-height: 28px;
  font-size: 10px;
}
.sum_ {
  background: #e7ecf3;
  line-height: 28px;
}
.section_ {
  border: 1px solid rgba(231, 236, 243, 1);
  border-radius: 7px;

  .title_ {
    height: 44px;
    line-height: 44px;
    background: rgba(239, 241, 245, 1);
    border-radius: 7px 7px 0px 0px;
    font-size: 12px;
    padding-left: 23px;
  }
  .content_ {
    padding: 0 24px;
  }
  .item_ {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    line-height: 42px;
    font-size: 12px;
    color: #333;
    &.no_border_ {
      border-bottom: none;
    }
  }
}
</style>
<script>
import ClaimAboutModals from "./components/ClaimAboutModals";
import ClaimButtons from "./components/ClaimButtons";

export default {
  data() {
    const columns = [
      {
        title: this.$t("claimDetail[12]") || "费用",
        dataIndex: "deductible",
        key: "deductible"
      },
      {
        title: this.$t("claimDetail[13]") || "医院发生金额",
        dataIndex: "amtIncurrdOrg",
        key: "amtIncurrdOrg",
        scopedSlots: { customRender: "amtIncurrdOrg" }
      },
      {
        title: this.$t("claimDetail[14]") || "折扣金额",
        dataIndex: "amtDiscount",
        key: "amtDiscount",
        scopedSlots: { customRender: "amtDiscount" }
      },
      {
        title: this.$t("claimDetail[15]") || "折后金额",
        dataIndex: "amtIncurred",
        key: "amtIncurred",
        scopedSlots: { customRender: "amtIncurred" }
      },
      {
        title: this.$t("claimCols[2]") || "赔付金额",
        dataIndex: "amtApproved",
        key: "amtApproved",
        scopedSlots: { customRender: "amtApproved" }
      },
      {
        title: this.$t("claimCols[3]") || "自负金额",
        dataIndex: "amtToPay",
        key: "amtToPay",
        scopedSlots: { customRender: "amtToPay" }
      }
    ];
    return {
      columns,
      data: [
        {
          deductible: 0
        }
      ],
      detailInfo: {}
    };
  },
  components: {
    "claim-about-modals": ClaimAboutModals,
    "claim-buttons": ClaimButtons
  },
  mounted() {
    const { id } = this.$route.params;
    this._id = id;
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$apis
        .queryMedicialDetail({
          claimsId: this._id
        })
        .then(res => res.data)
        .then(data => {
          this.detailInfo = data.data;
          this.detailInfo.cardNo = this.$route.query.cardNo;
          this.data = data.data.beneList;
        });
    }
  }
};
</script>
