var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.record.claimsId)?_c('div',[(_vm.showMore && _vm.type == 1)?_c('a-popover',{staticClass:"more_popover_",attrs:{"placement":"bottom"}},[_c('template',{slot:"content"},[_c('ul',{staticClass:"more_list_wrap_"},[(
            `${_vm.record.claimsStatus}${_vm.record.lastTransactionStatus}` == '10UD'
          )?_c('li',{on:{"click":function($event){return _vm.handleCancel(_vm.record)}}},[_vm._v(" "+_vm._s(_vm.$t('btns[21]') || '取消')+" ")]):_vm._e(),([_vm.$t('judge[1]') || '批准授权'].indexOf(_vm.statusName) > -1)?_c('li',{on:{"click":function($event){_vm.handlePrint(_vm.record, _vm.$t('btns[16]') || '打印事先授权批准表', 'PAA')}}},[_vm._v(" "+_vm._s(_vm.$t('btns[16]') || '打印事先授权批准表')+" ")]):_vm._e(),([_vm.$t('judge[2]') || '拒绝授权'].indexOf(_vm.statusName) > -1)?_c('li',{on:{"click":function($event){_vm.handlePrint(
              _vm.record,
              _vm.$t('btns[17]') || '打印事先授权拒绝通知',
              'PAD'
            )}}},[_vm._v(" "+_vm._s(_vm.$t('btns[17]') || '打印事先授权拒绝通知')+" ")]):_vm._e(),([_vm.$t('judge[0]') || '补件'].indexOf(_vm.statusName) > -1)?_c('li',{on:{"click":function($event){_vm.handleUpload(_vm.record, _vm.$t('btns[11]') || '上传资料')}}},[_vm._v(" "+_vm._s(_vm.$t('btns[11]') || '上传资料')+" ")]):_vm._e(),(
            _vm.record.claimsStatus === '10' &&
              _vm.record.lastTransactionStatus === '10'
          )?_c('li',{on:{"click":function($event){return _vm.handlePreAuth(_vm.record)}}},[_vm._v(" 继续申请预授权 ")]):_vm._e()])]),_c('a',{staticStyle:{"margin-left":"12px"}},[_vm._v(_vm._s(_vm.$t('btns[14]') || '更多'))])],2):(_vm.type == '2')?_c('div',[([_vm.$t('judge[1]') || '批准授权'].indexOf(_vm.statusName) > -1)?_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.handlePrint(_vm.record, _vm.$t('btns[16]') || '打印事先授权批准表', 'PAA')}}},[_vm._v(_vm._s(_vm.$t('btns[16]') || '打印事先授权批准表'))]):_vm._e(),([_vm.$t('judge[2]') || '拒绝授权'].indexOf(_vm.statusName) > -1)?_c('a-button',{staticClass:"mrg_l15_",attrs:{"type":"primary"},on:{"click":function($event){_vm.handlePrint(_vm.record, _vm.$t('btns[17]') || '打印事先授权拒绝通知', 'PAD')}}},[_vm._v(_vm._s(_vm.$t('btns[17]') || '打印事先授权拒绝通知'))]):_vm._e(),([_vm.$t('judge[0]') || '补件'].indexOf(_vm.statusName) > -1)?_c('a-button',{staticClass:"mrg_l15_",attrs:{"type":"primary"},on:{"click":function($event){_vm.handleUpload(_vm.record, _vm.$t('btns[11]') || '上传资料')}}},[_vm._v(_vm._s(_vm.$t('btns[11]') || '上传资料'))]):_vm._e()],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }