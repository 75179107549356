<template>
  <div class="page_">
    <div class="clearfix mrg_b15_">
      <a @click="$router.go(-1)">{{ $t('tips[42]') || '返回上一页' }}</a>
      <div class="f_r_">
        <auth-buttons
          @onSuccess="fetchData"
          type="2"
          :record="detailInfo"
        ></auth-buttons>
      </div>
    </div>
    <dl class="section_">
      <dt class="title_">{{ $t('claimDetail[0]') || '就诊信息' }}</dt>
      <dd class="content_">
        <a-row class="item_">
          <a-col :span="8">
            <div>
              {{ $t('claimDetail[1]') || '医院' }}{{ $t('colon')
              }}{{ detailInfo.providerName }}
            </div>
            <div>
              {{ $t('claimDetail[2]') || '住院天数' }}{{ $t('colon')
              }}{{ detailInfo.daysInHospital }}
            </div>
          </a-col>
          <a-col :span="8">
            <div>
              {{ $t('preAuthCols[1]') || '预计就诊日期' }}{{ $t('colon')
              }}{{ detailInfo.admissionDate }}
            </div>
            <div>
              {{ $t('claimDetail[4]') || '币种' }}{{ $t('colon')
              }}{{ detailInfo.currcdOrig }}
            </div>
          </a-col>
          <a-col :span="8">
            <div>
              {{ $t('preAuthCols[2]') || '预计账单金额' }}{{ $t('colon')
              }}{{ detailInfo.estBills }}
            </div>
            <div>{{ $t('claimDetail[5]') || '汇率' }}{{ $t('colon') }}-</div>
          </a-col>
        </a-row>
        <a-row class="item_">
          <a-col :span="8">
            <div>
              {{ $t('cols[0]') || '病人姓名' }}{{ $t('colon')
              }}{{ detailInfo.patientName }}
            </div>
            <div>
              {{ $t('cols[1]') || '出生日期' }}{{ $t('colon')
              }}{{ detailInfo.birthDate }}
            </div>
          </a-col>
          <a-col :span="8">
            <div>
              {{ $t('cols[2]') || '性别' }}{{ $t('colon')
              }}{{ detailInfo.gender }}
            </div>
            <div>
              {{ $t('claimDetail[20]') || '证件类型' }}{{ $t('colon')
              }}{{ detailInfo.idType }}
            </div>
          </a-col>
          <a-col :span="8">
            <div>
              {{ $t('claimDetail[21]') || '年龄' }}{{ $t('colon')
              }}{{ detailInfo.age }}岁
            </div>
            <div>
              {{ $t('cols[3]') || '证件号码' }}{{ $t('colon')
              }}{{ detailInfo.newIc }}
            </div>
          </a-col>
        </a-row>
        <a-row class="item_">
          <a-col :span="8">
            <div>
              {{ $t('claimDetail[22]') || '主被保险人' }}{{ $t('colon')
              }}{{ detailInfo.employeeName }}
            </div>
            <div>
              {{ $t('claimDetail[7]') || '邮箱' }}{{ $t('colon')
              }}{{ detailInfo.email }}
            </div>
          </a-col>
          <a-col :span="8">
            <div>
              {{ $t('claimDetail[6]') || '关系' }}{{ $t('colon')
              }}{{ detailInfo.relationship }}
            </div>
            <div></div>
          </a-col>
          <a-col :span="8">
            <div>{{ $t('claimDetail[23]') || '手机号' }}{{ $t('colon') }}-</div>
            <div></div>
          </a-col>
        </a-row>
        <a-row class="item_ no_border_">
          <a-col :span="24">
            {{ $t('Modals[27]') || '诊断' }}{{ $t('colon') }}-
          </a-col>
        </a-row>
      </dd>
    </dl>
    <dl class="section_">
      <dt class="title_">{{ $t('claimDetail[24]') || '事先授权信息' }}</dt>
      <dd class="content_">
        <a-row class="item_ no_border_">
          <a-col :span="6">
            {{ $t('claimDetail[28]') || '责任' }}{{ $t('colon')
            }}{{ detailInfo.loaDesc }}
          </a-col>
          <a-col :span="6">
            {{ $t('preAuthCols[11]') || '预授权编号' }}{{ $t('colon')
            }}{{ detailInfo.claimsId }}
          </a-col>
          <a-col :span="6">
            <div>
              {{ $t('claimDetail[25]') || '事先授权编号' }}{{ $t('colon')
              }}{{ detailInfo.authorizationCode }}
            </div>
          </a-col>
          <a-col :span="6">
            {{ $t('preAuthCols[4]') || '授权状态' }}{{ $t('colon')
            }}<span class="highlight_">{{
              detailInfo.lastTransactionStatusName
            }}</span>
          </a-col>
          <a-col :span="12">
            {{ $t('preAuth.preAuditCon') }}{{ $t('colon')
            }}{{ detailInfo.approvalReason || '-' }}
          </a-col>
          <a-col :span="6">
            {{ $t('preAuth.offcialserNum') }}{{ $t('colon')
            }}{{ detailInfo.realClaimsId || '-' }}
          </a-col>
          <a-col :span="24">
            {{ $t('claimCols[9]') || '事先授权结论' }}{{ $t('colon')
            }}{{ detailInfo.remarks || '-' }}
          </a-col>
        </a-row>
      </dd>
    </dl>
    <dl class="section_">
      <dt class="title_">
        文件
        <a-button
          v-if="
            detailInfo.claimsStatus == '10' &&
              detailInfo.lastTransactionStatus == '10'
          "
          type="primary"
          size="small"
          class="mrg_l15_"
          ghost
          @click="handleUpload(detailInfo, $t('btns[11]') || '上传资料')"
          >上传文件</a-button
        >
      </dt>
      <dd class="content_">
        <div class="preview-img" v-if="fileList && fileList.length">
          <div
            v-for="(item, i) in fileList"
            :key="i"
            class="mar-top10 preview-container"
          >
            <div class="preview-img-item">
              <img
                v-if="item.isImg"
                :src="item.url"
                title="点击放大"
                @click="showImage(item)"
              />
              <a-icon
                v-else
                class="file-icon"
                type="file"
                title="点击查看文件"
                @click="previewFile(item)"
              />
              <div class="file-name">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </dd>
    </dl>
    <claim-about-modals></claim-about-modals>
  </div>
</template>
<script>
import ClaimAboutModals from './components/ClaimAboutModals'
import AuthButtons from './components/AuthButtons'
import { imgReg, getPreviewFile } from '@/utils'
import { api as viewerApi } from 'v-viewer'
import { MESSAGE_CHANNEL } from '@/utils/constants'
import Bus from '@/utils/Bus'

export default {
  data() {
    return {
      detailInfo: {},
      fileList: []
    }
  },
  components: {
    'claim-about-modals': ClaimAboutModals,
    'auth-buttons': AuthButtons
  },
  computed: {
    // 过滤出图片数据
    justImgData() {
      return this.fileList.filter(item => {
        return item.isImg
      })
    }
  },
  mounted() {
    const { id } = this.$route.params
    this._id = id
    this.fetchData()
  },
  methods: {
    // 上传资料
    handleUpload(record, title) {
      Bus.$emit(MESSAGE_CHANNEL, {
        type: 'handleAuthUpload',
        data: record,
        title,
        onSuccess: () => {
          this.fetchData()
        }
      })
    },
    fetchData() {
      this.$apis
        .authDetail({
          claimsId: this._id
        })
        .then(res => res.data)
        .then(data => {
          this.detailInfo = data.data
          this.fileList = this.detailInfo.imageInfos || []
          this.getImgsPreviewData()
        })
    },
    // 获取附件完整路径
    getImgsPreviewData() {
      this.fileList.forEach((item, index) => {
        const isImg = imgReg.test(item.filenm && item.filenm.toLowerCase())
        // 图片类型且没有全路径地址 获取一下
        const obj = {
          url: item.filePath,
          name: item.filenm,
          isImg
        }
        this.$set(this.fileList, index, obj)
      })
    },
    // 查看图片放大图
    showImage(data) {
      let index = 0
      // 因为预览图片只是针对图片 index也应该是参照全部图片的index来界定
      for (let i = 0; i < this.justImgData.length; i++) {
        if (this.justImgData[i].url === data.url) {
          index = i
          break
        }
      }
      this.viewer = viewerApi({
        options: {
          fullscreen: false,
          navbar: true,
          title: false,
          toolbar: true,
          url: 'url',
          initialViewIndex: index,
          className: 'view-reset'
        },
        images: this.justImgData
      })
    },
    // 文件预览
    previewFile(row) {
      const { url } = row
      getPreviewFile(row, !!url, url => {
        row.url = url
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.f_r_ {
  float: right;
}
.mrg_b15_ {
  margin-bottom: 15px;
}
.mrg_l15_ {
  margin-left: 15px !important;
}
.highlight_ {
  color: #00bd59;
}
.section_ {
  border: 1px solid rgba(231, 236, 243, 1);
  border-radius: 7px;

  .title_ {
    height: 44px;
    line-height: 44px;
    background: rgba(239, 241, 245, 1);
    border-radius: 7px 7px 0px 0px;
    font-size: 12px;
    padding-left: 23px;
  }
  .content_ {
    padding: 24px;
  }
  .item_ {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    line-height: 42px;
    font-size: 12px;
    color: #333;
    &.no_border_ {
      border-bottom: none;
    }
  }
}
.preview-img {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  margin-bottom: 10px;
  .preview-container {
    width: 300px;
    margin: 0 10px;
    display: flex;
    align-items: center;
    .preview-img-item {
      position: relative;
      text-align: center;
      width: 300px;
      max-height: 400px;
      overflow: hidden;
      cursor: pointer;
      img {
        width: 100%;
        max-height: 380px;
      }
      .file-icon {
        color: #1890ff;
        font-size: 100px;
        padding: 50px 0;
      }
    }
  }
}
</style>
