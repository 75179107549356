<template>
  <div v-if="record.claimsId">
    <!-- 申请授权 - 无按钮
      等待授权批准11 - 无按钮
      批准授权12 - 出现打印“事先授权批准表”
      拒绝授权13 - 出现打印“事先授权拒绝通知”
      补件10或11 lastTransactionStatus: "04" - 出现上传资料
      报批保险人 -  - 无按钮 -->
    <a-popover
      v-if="showMore && type == 1"
      class="more_popover_"
      placement="bottom"
    >
      <template slot="content">
        <ul class="more_list_wrap_">
          <li
            @click="handleCancel(record)"
            v-if="
              `${record.claimsStatus}${record.lastTransactionStatus}` == '10UD'
            "
          >
            {{ $t('btns[21]') || '取消' }}
          </li>
          <li
            @click="
              handlePrint(record, $t('btns[16]') || '打印事先授权批准表', 'PAA')
            "
            v-if="[$t('judge[1]') || '批准授权'].indexOf(statusName) > -1"
          >
            {{ $t('btns[16]') || '打印事先授权批准表' }}
          </li>
          <li
            @click="
              handlePrint(
                record,
                $t('btns[17]') || '打印事先授权拒绝通知',
                'PAD'
              )
            "
            v-if="[$t('judge[2]') || '拒绝授权'].indexOf(statusName) > -1"
          >
            {{ $t('btns[17]') || '打印事先授权拒绝通知' }}
          </li>
          <li
            @click="handleUpload(record, $t('btns[11]') || '上传资料')"
            v-if="[$t('judge[0]') || '补件'].indexOf(statusName) > -1"
          >
            {{ $t('btns[11]') || '上传资料' }}
          </li>
          <li
            @click="handlePreAuth(record)"
            v-if="
              record.claimsStatus === '10' &&
                record.lastTransactionStatus === '10'
            "
          >
            继续申请预授权
          </li>
        </ul>
      </template>
      <a style="margin-left: 12px;">{{ $t('btns[14]') || '更多' }}</a>
    </a-popover>
    <div v-else-if="type == '2'">
      <a-button
        v-if="[$t('judge[1]') || '批准授权'].indexOf(statusName) > -1"
        @click="
          handlePrint(record, $t('btns[16]') || '打印事先授权批准表', 'PAA')
        "
        type="primary"
        >{{ $t('btns[16]') || '打印事先授权批准表' }}</a-button
      >
      <a-button
        v-if="[$t('judge[2]') || '拒绝授权'].indexOf(statusName) > -1"
        @click="
          handlePrint(record, $t('btns[17]') || '打印事先授权拒绝通知', 'PAD')
        "
        class="mrg_l15_"
        type="primary"
        >{{ $t('btns[17]') || '打印事先授权拒绝通知' }}</a-button
      >
      <a-button
        v-if="[$t('judge[0]') || '补件'].indexOf(statusName) > -1"
        @click="handleUpload(record, $t('btns[11]') || '上传资料')"
        class="mrg_l15_"
        type="primary"
        >{{ $t('btns[11]') || '上传资料' }}</a-button
      >
    </div>
  </div>
</template>
<style lang="scss" scoped></style>
<script>
import { MESSAGE_CHANNEL } from '@/utils/constants'
import Bus from '@/utils/Bus'

export default {
  props: {
    type: {
      type: String,
      default: ''
    },
    record: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    statusName() {
      let str =
        this.record.lastTransactionStatusName ||
        this.record.claimsStatusName ||
        ''
      return str.trim()
    },
    showMore() {
      if (!this.record && !this.record.claimsStatusName) {
        return false
      }
      const str = this.statusName
      if (
        this.type == '1' &&
        ([
          this.$t('judge[0]') || '补件',
          this.$t('judge[1]') || '批准授权',
          this.$t('judge[2]') || '拒绝授权'
        ].indexOf(str) > -1 ||
          (this.record.claimsStatus === '10' &&
            ['10', 'UD'].includes(this.record.lastTransactionStatus)))
      ) {
        return true
      }
      return false
    }
  },
  methods: {
    handleCancel(record) {
      this.$emit('cancel', {
        ...record
      })
    },
    handlePrint(record, title, printType) {
      Bus.$emit(MESSAGE_CHANNEL, {
        type: 'printAuthSuccess',
        data: record,
        title,
        printType
      })
    },
    handleUpload(record, title) {
      Bus.$emit(MESSAGE_CHANNEL, {
        type: 'handleAuthUpload',
        data: record,
        title,
        onSuccess: () => {
          this.$emit('onSuccess')
        }
      })
    },
    handlePreAuth(record) {
      Bus.$emit('handlePreAuth', {
        data: record
      })
    }
  }
}
</script>
